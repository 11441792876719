<template>
  <section class="hero has-hero-image is-between-small-and-medium">
    <div
      id="hero_image"
      :class="{ 'has-hero-color': !heroUri }"
      :style="'background-image: url(' + heroUri + ')'"
    />
    <BackLink />
    <router-link
      v-if="logoUri"
      id="logo"
      to="/"
      aria-label="Home"
    >
      <img class="logo" :src="logoUri" alt="Directory Hero">
    </router-link>
    <div class="hero-body">
      <div class="has-text-centered columns is-multiline is-vcentered">
        <div class="column is-12">
          <h1 v-if="heroText" class="title is-size-2 has-text-white" v-html="heroText" />
        </div>
        <HeroFeaturedSearchBar
          v-if="hasSearchProject"
          :directories="directories"
          :search-project="searchProject"
        />
        <HeroDirectoryButtons
          v-else
          :directories="directories"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import HeroDirectoryButtons from '@/components/Home/HeroDirectoryButtons.vue';
import HeroFeaturedSearchBar from '@/components/Home/HeroFeaturedSearchBar.vue';
import BackLink from './BackLink.vue';

export default {
  computed: {
    ...mapState({
      directories: (state) => state.directories,
      logoUri: (state) => state.logo_uri,
      heroUri: (state) => state.hero_uri,
      heroText: (state) => state.hero_text,
      searchProject: (state) => state.primary_search_project,
    }),
    hasSearchProject() {
      return !!this.searchProject;
    },
  },
  components: {
    HeroDirectoryButtons,
    HeroFeaturedSearchBar,
    BackLink,
  },
};
</script>
