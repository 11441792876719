<template>
  <section class="page">
    <HomeHero />
    <div id="stat-panel" class="level has-text-centered">
      <div v-if="recordCount > 0" class="level-item">
        <div>
          <p class="heading">
            Records
          </p>
          <p class="title">
            {{ recordCount.toLocaleString() }}
          </p>
        </div>
      </div>
      <div class="level-item">
        <div>
          <p class="heading">
            Directories
          </p>
          <p class="title">
            {{ directoryCount.toLocaleString() }}
          </p>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="columns is-multiline is-vcentered">
        <router-link
          v-for="(directory, index) in directories"
          :key="directory.slug"
          :to="routerLink(directory)"
          :class="`column is-${'5775'.charAt(index % 4)} has-text-centered`"
        >
          <div :style="'background-image:url(' + directory.hero_uri + ')'" class="directory">
            <h3 class="title has-text-white is-unselectable">
              {{ directory.name }}
            </h3>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import HomeHero from '@/components/Global/HomeHero.vue';

export default {
  computed: {
    ...mapState({
      directories: (state) => state.directories.filter(
        (directory) => directory.slug !== state.primary_search_project,
      ),
      recordCount: (state) => state?.total_records ?? 0,
    }),
    ...mapGetters({
      directoryCount: 'directory/count',
    }),
  },
  methods: {
    routerLink(directory) {
      return `/${directory.slug}/home`;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  components: {
    HomeHero,
  },
};
</script>
