<template>
  <div class="home-hero-directory-buttons column is-12">
    <nav class="buttons has-text-centered is-centered">
      <router-link
        v-for="directory in directories"
        :key="directory.id"
        :to="`/${directory.slug}/home`"
        class="button is-white has-text-weight-bold is-medium"
      >
        {{ directory.name.toUpperCase() }}
      </router-link>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'HeroDirectoryButtons',
  props: {
    directories: {
      type: Array,
      required: true,
    },
  },
};
</script>
